<template>
  <div class="pc-bottom-bar">
    <div class="top">
      <div class="top-item">
        <h3>关于碳圈</h3>
        <p @click="gopage(1)">关于我们</p>
        <p @click="gopage(2)">联系我们</p>
        <!-- <p @click="gopage(3)">加入我们</p> -->
        <p @click="gopage(4)">合作伙伴</p>

      </div>
      <div class="top-item">
        <h3>相关链接</h3>
        <p @click="goUrl('http://www.carbonconf.com' ,'_blank ')">碳材料大会</p>
        <p @click="goUrl('https://www.toutiao.com/c/user/token/MS4wLjABAAAAeCWbd7znTwFt47flN8s4aaW-yWTxkykyPU6KGuZn858/?','blank')">头条
        </p>
        <p @click="goUrl('https://www.douyin.com/user/MS4wLjABAAAAUF3UHydrFkAu5HjLcdWoWkDUQbIDWgT2LXftYqdKhvghJ0-j3KP7MzL6CSbgAOvI','_blank ')">
          抖音</p>

          <p @click="goUrl('https://author.baidu.com/home/1692370015821239' ,'_blank ')">百家号</p>
          <p @click="goUrl('https://weibo.com/u/7614783910' ,'_blank ')">微博</p>
        <!-- <p>CarbonWeek</p> -->

      </div>
      <div class="top-item">
        <div>
          <h4>扫码关注“Carbontech”公众号</h4>
          <p>获取更多资讯和供需信息！</p>
        </div>
        <div>
          <img src="@/assets/images/wxCar.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="bottom">
      Icp备案号 <span @click='goToLink()'>粤ICP备2023045353号</span> 深圳市德泰中研信息科技有限公司 版权所有
    </div>

  </div>
</template>
<script>

export default {
  name: "footers",

  data() {
    return {};
  },
  methods: {
    gopage(key) {
      switch (key) {
        case 1:
          this.$router.push("/aboutus/we")
          break;
        case 2:
          this.$router.push("/aboutus/concat")
          break;
        case 3:
          this.$router.push("/aboutus/join")
          break;
        case 4:
          this.$router.push("/aboutus/cooperation")
          break;

        default:
          break;
      }
      // if(this.$route.path !="/aboutus/we"){
      //   this.$store.commit("setactivepath", "/index");
      //   this.$router.push("/aboutus")
      // }
    },
    goUrl(url, mothods) {
      window.open(url, mothods);
    },
    goToLink() {
      window.open("https://beian.miit.gov.cn/", "_blank ")
    }
  }
};
</script>

<style lang="less" scoped>
.pc-bottom-bar {
  width: 100%;
  background: white;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 20px;

  .top {
    width: 1280px;
    margin: 0 auto;
    display: flex;

    .top-item {
      flex: 1;
      padding: 20px;
      padding-left: 70px;
      margin-bottom: 20px;

      p {
        cursor: pointer;
      }
    }

    .top-item:nth-child(2) {
      border-left: 2px solid #eaeaea;
      border-right: 2px solid #eaeaea;
    }

    .top-item:nth-child(3) {
      display: flex;
      // align-items: center;
      padding-left: 70px;

      img:nth-child(1) {
        position: relative;
        left: 50px;
        width: 90px;
      }
    }
  }

  .bottom {
    clear: both;
    width: 1280px;
    margin: 0 auto;
    text-align: center;
    font-size: 14px;
    padding-top: 20px;

    span {
      cursor: pointer;
    }
  }
}</style>