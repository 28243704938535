import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/global.css"
import global from './assets/global/global';
import "./assets/iconfont/iconfont"
import "./assets/iconfont/iconfont.css"
import store from "./store";
import "./assets/css/font.css"
// import "./utils/rem";


Vue.config.productionTip = false
Vue.prototype.global = global
Vue.mixin({
  data() {
    return {
      imgPrefix: "https://mcnki.obs.cn-east-2.myhuaweicloud.com",
    }
  }
})
Vue.use(ElementUI);
new Vue({
  router,
  store,
  // pinia,
  render: h => h(App)
}).$mount('#app')
