import axios from 'axios' //引入axios
import { Message } from "element-ui";
import router from "../router/index";
//1.利用axios对象的create方法去创建一个axios示例
//2.request就是axios,只不过可以配置参数
const requests = axios.create({
  //配置对象
  //baseURL 基础路径，发请求的时候，路径当中会出现api
  baseURL: "https://admin.card.polydt.com",
  timeout: 5000 ,//请求超时超过5s代表请求失败
  headers: { "Content-Type": "Application/json" },
})

//请求拦截器：在发起请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情
//interceptors 拦截器的意思
requests.interceptors.request.use(
  // config 是一个配置对象，该对象里面有一个属性很重要，就是header请求头
  (config) => {
    let token = window.localStorage.getItem("token")
    if(token){
      config.headers["Authorization"] = `Bearer ${token}`
      return config
    }else{
      return config
    }
  },
  (error) => {
      return Promise.reject(error);
  }
)


// requests.interceptors.request.use((config)=>{
// return config
// })

//响应拦截器
//响应拦截器
requests.interceptors.response.use((res) => {
  //响应成功的回调函数，服务器响应数据回来后，响应拦截器可以检测到，可以做一些事情
  console.log(res)
  switch (res.data.result_code) {
    // 未登录 传递参数在登录成功后返回当前页面
    case 401:
      router.replace({
        name: "/login",
        query: { redirect: router.currentRoute.name }
      });
      break;
    // token过期 传递参数在登录成功后返回当前页面
    case 403:
      Message.error("登录过期，请重新登录!");
      localStorage.removeItem("token");
      router.replace({
        name: "/login",
        query: {
          redirect: router.currentRoute.name
        }
      });
      break;
  }
  return res;
  // return res
}, (error) => {
  //服务器响应失败的回调函数
  console.log("服务器响应失败回调", error);
  if (typeof error.response.data.message === "string") {
      Message.error(error.response.data.message);
  }
  //  else if(error.response.status == 401 ){
  //   router.replace({
  //     name: "/login",
  //     query: { redirect: router.currentRoute.name }
  //   });
  // } 
  else {
      Message.error(error.response.data.message[0][0]);
  }
  return Promise.reject(error.response.data);
})

//对外暴露
export default requests