import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/index', meta: { keepAlive: true, title: "碳材料圈" } },
  { path: "/index", name: 'index', component: () => import('../views/home'), meta: { keepAlive: true, title: "碳材料圈" } },
  { path: "/consulting", name: 'consulting', component: () => import('../views/consulting'), meta: { keepAlive: true, title: "资讯" } },
  { path: "/supplydemand", name: 'supplydemand', component: () => import('../views/supplydemand'), meta: { keepAlive: true, title: "供需" } },
  { path: "/companylibrary", name: 'companylibrary', component: () => import('../views/companylibrary'), meta: { keepAlive: true, title: "公司库" } },
  { path: "/companyDetail", name: 'companyDetail', component: () => import('../views/companyDetail'), meta: { keepAlive: true, title: "公司详情" } },
  { path: "/circleDetailsPage", name: 'circleDetailsPage', component: () => import('../views/circleDetail'), meta: { keepAlive: true, title: "圈子详情" } },
  { path: "/maillist", name: 'maillist', component: () => import('../views/maillist'), meta: { keepAlive: true, title: "通讯录" } },
  { path: "/productDetails", name: 'productDetails', component: () => import('../views/productDetail'), meta: { keepAlive: true, title: "产品详情" } },
  { path: "/expertDetails", name: 'expertDetails', component: () => import('../views/expertDetail'), meta: { keepAlive: true, title: "专家详情" } },
  { path: "/activity", name: 'activity', component: () => import('../views/activity'), meta: { keepAlive: true, title: "活动" } },
  { path: "/curriculum", name: 'curriculum', component: () => import('../views/curriculum'), meta: { keepAlive: true, title: "课程" } },
  { path: "/DetailsPage", name: 'DetailsPage', component: () => import('../views/DetailsPage'), meta: { keepAlive: true, title: "详情" } },
  {
    path: "/aboutus", name: 'aboutus', component: () => import('../views/aboutus'), meta: { keepAlive: true, title: "关于我们" }, 
    redirect:"/aboutus/we",
    children: [
      {path:"/aboutus/we",name:'about',component:()=>import("../views/aboutus/about"),meta: { keepAlive: true, title: "关于我们" }},
      {path:"/aboutus/concat",name:"concats",component:()=>import("../views/aboutus/contact"),meta:{keepAlive:true,title:"联系我们"}},
      {path:"/aboutus/join",name:"join",component:()=>import("../views/aboutus/join"),meta:{keepAlive:true,title:"联系我们"}},
      {path:"/aboutus/cooperation",name:"cooperation",component:()=>import("../views/aboutus/cooperation"),meta:{keepAlive:true,title:"联系我们"}},
    ]
  },
  {path:"/release",name:'release',component:()=>import("../views/release"),meta:{keepAlive:true,title:"发布供需"}},
  { path: "/login", name: 'login', component: () => import('../views/login'), meta: { keepAlive: false, title: "登录" } },
  {
    path: "/userinfo", name: "PersonalCenter", component: () => import('../views/PersonalCenter'), meta: { keepAlive: true, title: "个人中心" }
    ,redirect: "/userinfo/basics",
    children: [
      //  基础信息
      {
        path: "/userinfo/basics",
        name: "Basics",
        component: () => import("@/views/PersonalCenter/Basics"),meta: { keepAlive: true, title: "基础信息" }
      },
      //  账户安全
      {
        path: "/userinfo/Accountsecurity",
        name: "Accountsecurity",
        component: () => import("@/views/PersonalCenter/Accountsecurity"),meta: { keepAlive: true, title: "账户安全" }
      },
       //  我的供需
       {
        path: "/userinfo/Mysupply",
        name: "Mysupply",
        component: () => import("@/views/PersonalCenter/Mysupply"),meta: { keepAlive: true, title: "我的供需" }
      },
      //  收藏的专家
      {
       path: "/userinfo/MyExpert",
       name: "MyExpert",
       component: () => import("@/views/PersonalCenter/MyExpert"),meta: { keepAlive: true, title: "收藏的专家" }
     },
      //  收藏的企业
      {
        path: "/userinfo/MyCompany",
        name: "MyCompany",
        component: () => import("@/views/PersonalCenter/MyCompany"),meta: { keepAlive: true, title: "收藏的企业" }
      },
       //  收藏的产品
       {
        path: "/userinfo/Myproducts",
        name: "Myproducts",
        component: () => import("@/views/PersonalCenter/Myproducts"),meta: { keepAlive: true, title: "收藏的产品" }
      },
    
      
  
   
     
      
    ]
  },
  // 移动端基础信息
  {
    path:"/userinfo/MobBasics",
    name:"MobBasics",
    component:()=>import("@/views/PersonalCenter/Mobbasics")
  },
  {
    path:"/userinfo/MobAccountsecurity",
    name:"MobAccountsecurity",
    component:()=>import("@/views/PersonalCenter/MobAccountsecurity")
  },
  {
    path:"/userinfo/MobBusinessmanagement",
    name:"MobBusinessmanagement",
    component:()=>import("@/views/PersonalCenter/Mobbusinessmanagement")
  },
  {
    path:"/userinfo/MobCoupon",
    name:"MobCoupon",
    component:()=>import("@/views/PersonalCenter/Mobcoupon")
  },
]

const router = new VueRouter(
  {
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  },
)

// 路由守卫--路由跳转之后触发;
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0); //跳转之后回到顶部
});
// 路由守卫--路由跳转之前触发;
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    window.location.href = 'http://m.carbonlinks.cn'
    return
  }
  next();
});


export default router
