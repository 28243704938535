<template>
  <div class="header">
    <div class="header-box">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="" @click="goindex" />
      </div>
      <div class="main">
        <div
          class="main-item"
          @click="changenav(item.path)"
          :class="
            $store.state.activepath == item.path ? 'active-main-item' : null
          "
          v-for="(item, index) in navlist"
        >
          {{ item.name }}
        </div>
        <!-- <div class="search-box">
          <input
            type="text"
            placeholder="搜索"
            v-model="resulttext"
            @focus="obtaininput"
            @blur="loseinput"
            @click="obtaininput"
            @change="searchtext"
          />
          <i class="iconfont icon-fangdajing"></i>

          <transition name="fade">
            <div class="search-text-box" v-show="searchboxflag">
              <div v-for="item in resultlist" :key="item.id">
                {{ item.title }}
              </div>
            </div>
          </transition>
        </div> -->
      </div>
      <div class="login" v-if="!userinfoflag" @click="changenav('/login')">
        登录
      </div>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <div class="login_box" v-if="userinfoflag">
            <!-- <el-avatar   v-if="userinfo.avatar"  :src="imgPrefix + userinfo.avatar" fit="fill"></el-avatar> -->
            <el-avatar   v-if="userinfo.avatar"  :src="userinfo.avatar" fit="fill"></el-avatar>
             <el-avatar  icon="el-icon-user-solid"  v-else  ></el-avatar>
            <span>{{ userinfo.realName }}</span>
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-s-custom"
            command="a"
          
            >个人中心</el-dropdown-item
          >
          <el-dropdown-item icon="el-icon-error" command="b"
            >退出登陆</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { getsearch, getuserinfo } from "@/api";
export default {
  name: "topheader",
  data() {
    return {
      navlist: [
        { name: "首页", path: "/index" },
        { name: "资讯", path: "/consulting" },
        { name: "供需", path: "/supplydemand" },
        { name: "公司库", path: "/companylibrary" },
        { name: "通讯录", path: "/maillist" },
        { name: "活动", path: "/activity" },
        { name: "课程", path: "/curriculum" },
        // { name: "个人中心", path: "/userinfo" },
      ],
      searchboxflag: false,
      resulttext: "",
      resultlist: [],
      userinfo: {},
      userinfoflag: false,
    };
  },

  methods: {
    changenav(path) {
      this.$store.commit("setactivepath", path);
      this.$store.commit("setsecondpath", this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    obtaininput() {
      this.searchboxflag = true;
    },
    loseinput() {
      this.searchboxflag = false;
      this.resulttext = "";
    },
    handleScroll() {
      // let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, '滚动距离')
      this.searchboxflag = false;
      this.resultlist = [];
    },
    goindex() {
      this.$store.commit("setactivepath", "/index");
      this.$store.commit("setsecondpath", this.$route.path);
      if (this.$route.path !== "index") {
        this.$router.push("/index");
      }
    },
    searchtext() {
      getsearch(this.resulttext)
        .then((res) => {
          console.log(res);
          this.resultlist = res.data;
        })
        .catch((err) => {
          this.resultlist = [{ title: "没搜到结果" }];
        });
    },
  
    handleCommand(command) {
      console.log(command)
      switch (command) {
        case "a":
          this.$router.push("/userinfo");
          break;
        case "b":
          this.$confirm('退出登陆, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
         this.userinfoflag = false;
          this.userinfo = {};
          localStorage.setItem("token", "");
          window.localStorage.setItem("userName","");
           this.$router.push("/index");
        }).catch(() => {
                 
        });
          
          break;

        default:
          break;
      }
    },
  },
  mounted() {
    getuserinfo()
      .then((res) => {
        this.userinfo = res.data;
        console.log(this.userinfo)
        this.userinfoflag = true;
      })
      .catch((err) => {
        console.log(err);
        localStorage.setItem("token", "");
        localStorage.setItem("userName", "");
        this.userinfo = {};
        this.userinfoflag = false;
      });
    this.activepath = window.location.pathname;
    this.$store.commit("setactivepath", window.location.pathname);
    if (this.activepath == "/") {
      this.$store.commit("setactivepath", "/index");
    } else if (this.activepath == "/DetailsPage") {
      let pathtext = JSON.parse(localStorage.getItem("secondpath"));
      this.$store.commit("setactivepath", pathtext);
    }
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
  },
  beforeRouteEnter(to, form, next) {
    console.log(to);
    console.log(form);
    console.log(next);
  },
};
</script>

<style lang="less" scoped>

.el-dropdown{
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  height: 65px;
  background: #0966a9;
  .header-box {
    color: #d9ecfb;
    min-width: 1000px;
    height: 65px;
    width: 1280px !important;
    margin: 0 auto;
    display: flex;
    line-height: 65px;
    justify-content: space-between;
    .logo {
      width: 150px;
      line-height: 80px;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
    .main {
      // width: 100%;
      // min-width: 1050px;
      // margin: 0 50px;
      display: flex;
      .main-item {
        position: relative;
        padding: 0 27px;
        font-size: 18px;
        cursor: pointer;
        &:hover {
          background: white;
          color: #0966a9;
          font-weight: bold;
          &::after {
            width: 100%;
            left: 0%;
            background: white;
            border-radius: 5px 5px 0 0;
            content: "";
            height: 5px;
            position: absolute;
            bottom: 0;
          }
        }
      }
      .active-main-item {
        position: relative;
        font-weight: bold;
        color: #0966a9;
        padding: 0 27px;
        background: white;
        cursor: pointer;
        &::after {
          width: 100%;
          left: 0%;
          background: white;
          border-radius: 5px 5px 0 0;
          content: "";
          height: 5px;
          position: absolute;
          bottom: 0;
          transition: all 0.3s;
        }
      }
      .search-box {
        position: relative;
        margin-left: 20px;
        input {
          padding: 7px 20px;
          padding-right: 35px;
          outline: none;
          border-radius: 16px;
          background: white;
          border: 1px solid #277fbe;
          background: #277fbe;
          color: white;
          &::-webkit-input-placeholder {
            color: white;
          }
        }
        .iconfont {
          color: white;
          position: relative;
          left: -30px;
        }
        .search-text-box {
          position: absolute;
          width: 268px;
          right: 15px;
          background: white;
          border-radius: 8px;
          margin-top: -10px;
          padding: 20px 24px 24px;
          // box-shadow: 0 2px 10px 0 rgb(102 103 116 / 30%);
          box-shadow: 0 2px 10px 0 #0000005e;
          z-index: 2002 !important;
          color: #606266;
          font-size: 14px;
          line-height: 1.4;
          text-align: justify;
        }
      }
    }
    .login {
      // margin-right: 10px;
          text-align: right;
    cursor: pointer;
    position: absolute;
    width: 240px;
    right: 50%;
    top: 0;
    margin-right: -640px;
    }
    .login_box {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        color: #d9ecfb;
        margin-left: 10px;
      }
    }
  }
}

// 动画效果

/* 进场动画   元素显示时应用的动画 */
.fade-enter {
  // 初始动画
  opacity: 0;
}
.fade-enter-active {
  // 初始状态到终止状态执行
  // 这个类可以被用来定义过渡的过程时间，延迟和曲线函数。
  transition: all 1s;
}
.fade-enter-to {
  // 最终停留状态
  opacity: 1;
}

/* 离场动画   元素消失时应用的动画 同理进场动画 */
.fade-leave {
  opacity: 1;
}
.fade-leave-active {
  transition: all 1s;
}
.fade-leave-to {
  opacity: 0;
}
</style>