import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: () => {
    return {
      // 被选中那块亮了
      activepath: "/index",
      secondpath: "/", //详情页导航栏的文字切换标准
      userInfo: "",
      videoDialog: { show: false, item:{} },
    circleDialog:{ show: false, url: "",title:"" },
    }
  },
  mutations: {
    setactivepath(state, newVal) {
      state.activepath = newVal
    },
    setsecondpath(state,newVal){
      state.secondpath = newVal
    },
    setUserInfo(state, newValue) {
      state.userInfo = newValue;
    },
    setVideoDialog(state, newValue) {
      state.videoDialog = newValue;
    },
  },
})